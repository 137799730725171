.fc-timegrid-slot {
  height: 80px !important ; /* Augmenter cette valeur pour augmenter la hauteur des lignes des heures */
}
.event-content {
  position: relative;
}

.event-content .firstname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2em;
  width: 100%;
}
.calendar-container {
  margin-top: 2%;
  max-width: 95%; /* Vous pouvez ajuster cette valeur en fonction de vos préférences */
  margin-left: auto;
  margin-right: auto;
}
.event-content .button {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 50px;
}

@media screen and (max-width: 650px) {
  .event-content .firstname {
    padding-right: 0;
    margin-top: 30px;
  }

  .event-content .button {
    right: auto;
  }
  .calendar-container {
    max-width: 1500px;
  }
}
